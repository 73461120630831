<template>
  <v-container fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#fe4a4b"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />

    <v-card-text>
      <v-container class="mt-n16">
        <v-row>
          <!-- <v-col cols="14" sm="2" md="2">
            <v-row dense class="mb-5">
              <v-btn color="primary">
                <v-icon color="white">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </v-row></v-col
          > -->
          <v-col cols="14" sm="10" md="10">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="primary" dark @click="dialog = true">
                  <v-icon color="white">
                    mdi-plus-circle
                  </v-icon>
                  <v-card-text class="white--text font-weight-bold">
                    Add New Member
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
          <v-col cols="14" sm="2" md="2">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="primary" dark @click="downloadCSV">
                  <v-icon color="white">
                    mdi-download-circle
                  </v-icon>
                  <v-card-text class="white--text font-weight-bold">
                    CSV
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-container class="mt-n10">
      <material-card
        color="primary"
        title="MEMBERS"
        :imageSrc="require('@/assets/logoRR.png')"
      >
        <v-card-text>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="tableHeaders"
            :items="members"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:[`item.more`]="{ item }">
              <div @click="$router.push(`member/${item.memberId}`)">
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="black" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>More Info</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              <div>
                <v-chip
                  label
                  style="color:blue; width:100%;  justify-content:center;"
                  v-if="item.gender === 0"
                >
                  Male
                </v-chip>
                <v-chip
                  label
                  style="color:green;  width:100%;  justify-content:center;"
                  v-if="item.gender === 1"
                >
                  Female
                </v-chip>
              </div>
            </template>

            <template v-slot:[`item.dateOfBirth`]="{ item }">
              {{ item.dateOfBirth.year }}/{{ item.dateOfBirth.month }}/{{
                item.dateOfBirth.day
              }}
            </template>
          </v-data-table></v-card-text
        >
      </material-card>
    </v-container>
    <v-container>
      <v-dialog v-model="dialog" fullscreen origin="top right">
        <v-card style="background-color:#ecedf3">
          <v-row style="float:right; margin-top:10px; margin-right:10px;">
            <div>
              <v-icon color="red" @click="close">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>

          <v-card-text>
            <v-container class="main-form" style="">
              <div class="logo-image">
                <v-img
                  style="   transform: translateX(100%) "
                  :src="require('@/assets/logoRR.png')"
                />
              </div>
              <div class="logo-image-mobile">
                <v-img :src="require('@/assets/logoRR.png')" />
              </div>
              <div style=" padding:25px; background-color:white">
                <div class="main-title">
                  <div class="form-title">RALLY4EVER Registration Form</div>
                </div>

                <v-row>
                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.memberNumber"
                      label="Member No"
                      outlined
                      clearable
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.firstName"
                      label="First Name"
                      outlined
                      clearable
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <!-- <v-text-field
                      label="Legal middle name"
                      hint="example of helper text only on focus"
                    ></v-text-field> -->
                    <v-text-field
                      v-model="editedItem.lastName"
                      label="Last Name"
                      outlined
                      clearable
                      required
                    />
                  </v-col>

                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                      outlined
                      clearable
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <v-text-field
                      type="number"
                      class="numberInput"
                      v-model="editedItem.mobile"
                      label="Mobile"
                      outlined
                      clearable
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <v-text-field
                      type="number"
                      class="numberInput"
                      v-model="editedItem.telephone"
                      label="Telephone"
                      outlined
                      clearable
                      required
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <v-select
                      v-model="editedItem.gender"
                      label="Gender"
                      :items="gender"
                      outlined
                      clearable
                      required
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <v-text-field
                      v-model="initialFinal"
                      label=" Date Of Birth"
                      @click="dateModalActive()"
                      outlined
                      clearable
                      required
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <v-select
                      v-model="editedItem.location"
                      label="Location"
                      :items="locations"
                      outlined
                      clearable
                      required
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.postcode"
                      label="Postal Code"
                      outlined
                      clearable
                      required
                    />
                  </v-col>
                   <v-col cols="12" sm="12" md="12" class="">
                  Can we save your information so you don't re-fill each week ?
                  <v-radio-group
      v-model="column"
      row
    >
      <v-radio
        label="YES"
        value="radio-1"
      ></v-radio>
      <v-radio
        label="NO"
        value="radio-2"
      ></v-radio>
    </v-radio-group>
                  </v-col>
                
                </v-row>
              </div>

              <!-- <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="validate">
                  Save
                </v-btn>
              </v-card-actions> -->

              <div class="btn-group">
                <div class="btn  primary-bg" @click="close">Cancel</div>
                <div class="btn secondary-bg" text @click="validate">Save</div>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog class="date-modal" v-model="dateModal" persistent width="290px">
        <div>
          <v-date-picker v-model="datePick" color="primary">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDateModal">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="addDate">
              OK
            </v-btn>
          </v-date-picker>
        </div>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var moment = require("moment");

export default {
  components: { Loading },
  data: () => ({
    isLoading: false,
    loadingSize: 128,
    loadingFullPage: true,
    search: "",
    tableHeaders: [
      // { text: "memberId", value: "memberId" },

      { text: "Member No", value: "memberNumber" },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "DOB", value: "dateOfBirth" },

      { text: "Gender", value: "gender" },
      { text: "E-Mail", value: "email" },
      { text: "Mobile", value: "mobile" },
      { text: "Telephone", value: "telephone" },
      { text: "Location", value: "location" },
      { text: "Post Code", value: "postcode" },
      { text: "", value: "more" }
    ],
    gender: [
      { text: "Male ", value: 0 },
      { text: "Female ", value: 1 }
    ],
    editedItem: {
      memberNumber: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      telephone: "",
      mobile: "",
      gender: 0,
      location: "",
      postcode: ""
    },
    locations: [
      "NSW - Artarmon Tennis",
      "NSW - Bar Beach Tennis",
      "NSW - Hope Street Tennis",
      "NSW - Parkes Tennis",
      "NSW - Parkland Tennis",
      "NSW - Rushcutters Bay Boxing",
      "NSW - Rushcutters Bay Fitness",
      "NSW - Wolloomooloo Tennis",
      "QLD - Marcoola Tennis",
      "QLD - Redlands Tennis",
      "VLC - Bundoora Tennis",
      "VLC - Fawkner Tennis"
    ],
    dialogDelete: false,
    dialog: false,
    members: [],
    dateModal: false,
    datePick: "",
    initialFinal: "",

    itemsFormatted: [],
    fileTitle: "RR_Members_List",
    csvHeaders: {
      //createdAt: "Created Time",
      memberNumber: "Member Number",
      firstName: "First Name",
      lastName: "Last Name",
      dateOfBirth: "Date Of Birth",
      gender: "Gender",
      email: "Email",
      mobile: "Mobile",
      telephone: "Telephone",
      location: "Location",
      postcode: "Post Code"
    }
  }),

  computed: {},
  watch: {},
  filters: {
    moment: function(date) {
      return moment
        .utc(date)
        .local()
        .format("MMMM Do YYYY - h:mm a");
    }
  },
  created() {
    this.getData();
  },
  methods: {
    downloadCSV() {
      this.members.forEach(item => {
        item.createdAt = moment(item.createdAt).format("MMMM Do YYYY - h:mm a");
        if (item.gender == 0) {
          item.gender = "Male";
        } else if (item.gender == 1) {
          item.gender = "Female";
        }
        this.itemsFormatted.push({
          // memberId: item.memberId,
          //  createdAt: item.createdAt,
          memberNumber: item.memberNumber,
          firstName: item.firstName,
          lastName: item.lastName,
          dateOfBirth:
            item.dateOfBirth.year +
            "/" +
            item.dateOfBirth.month +
            "/" +
            item.dateOfBirth.day,

          gender: item.gender,

          email: item.email,
          mobile: item.mobile,
          telephone: item.telephone,
          location: item.location,
          postcode: item.postcode
        });
      });
      // console.log(this.itemsFormatted);
      this.exportCSVFile(this.csvHeaders, this.itemsFormatted, this.fileTitle);
    },
    exportCSVFile(headers, items, fileTitle) {
      console.log("exportCSVFile");

      console.log(headers);
      console.log(items);

      console.log(fileTitle);

      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle || "export.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      this.clearData();
    },
    convertToCSV(objArray) {
      console.log("convertToCSV");
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    clearData() {
      this.itemsFormatted = [];
      console.log("Cleared");
    },
    viewMember(row) {
      console.log("item");
      console.log(row);
      console.log(row.memberId);
      // this.$router.push()
    },

    getData() {
      this.isLoading = true;
      this.$http.get("/Member").then(response => {
        this.members = response.data.data;
        this.members = this.members.reverse();
        console.log(this.members);

        this.isLoading = false;
      });
    },
    close() {
      this.dialog = false;
    },
    dateModalActive() {
      var local = new Date();
      this.date = moment(local).format("YYYY-MM-DD");

      this.time = "00:00:00";

      this.dateModal = true;
    },
    addDate() {
      var date = this.datePick.concat("T").concat(this.time);
      var utcDate = moment.utc(moment(date).utc()).format();
      this.editedItem.dateOfBirth = utcDate;

      console.log(this.editedItem.dateOfBirth);
      this.initialFinal = moment(this.editedItem.dateOfBirth).format(
        "MMMM Do YYYY"
      );

      this.dateModal = false;
    },
    closeDateModal() {
      this.editedItem.dateOfBirth = "";
      this.dateModal = false;
    },
    validate() {
      // console.log(this.editedItem);
      if (
        this.editedItem.memberNumber == "" &&
        this.editedItem.firstName == "" &&
        this.editedItem.lastName == "" &&
        this.editedItem.email == ""
      ) {
        this.$toast.error("Please fill the required fields.", "Error", {
          position: "topRight"
        });
      } else {
        this.save();
      }
    },
    save() {
      console.log(this.editedItem);
      this.isLoading = true;
      this.$http
        .post("/Member", this.editedItem)
        .then(response => {
          if (response.data.success === true) {
            console.log(response);
            this.members.unshift(response.data.data);
            this.isLoading = false;
            this.close();
            this.$toast.success("Member added successfully.", "Success", {
              position: "topRight"
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          console.log("error", error);
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    }
  }
};
</script>
<style scoped>
.date-modal {
  overflow: hidden;
}
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.main-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
}
.logo-image {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
.logo-image-mobile {
  width: 200px;
  padding-bottom: 10px;

  display: none;
  margin-left: auto;
  margin-right: auto;
}
.form-title {
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  color: black;
  padding: 20px;
}
.main-form {
  width: 600px;
}
.btn-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}
.btn {
  flex: auto;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px;
  margin: 3px;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  cursor: pointer;
}
.btn:hover,
.btn:focus {
  opacity: 0.8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.primary-bg {
  background-color: #757373a4;
}
.secondary-bg {
  background-color: #18bd5b;
}
@media (max-width: 420px) {
  .main-form {
    width: 400px;
    margin-left: -20px;
  }
  .logo-image {
    display: none;
  }
  .logo-image-mobile {
    display: block;
  }
}
@media (max-width: 400px) {
  .main-form {
    width: 380px;
    margin-left: -20px;
  }
}
@media (max-width: 375px) {
  .main-form {
    width: 365px;
    margin-left: -20px;
  }
}
@media (max-width: 360px) {
  .main-form {
    width: 350px;
    margin-left: -20px;
  }
}
</style>
